import Trans from 'next-translate/Trans';
import withTranslation from 'next-translate/withTranslation';
import { memo } from 'react';

import { Box, Icon, Modal, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EIcon, ESize, ETypographyVariant, WithTranslation } from '@core/type';

const ModalHeaderContainer = ({ i18n: { t } }: WithTranslation) => (
  <Modal.Header>
    <Box display="flex" gap="16px" alignItems="center" marginBottom="16px" justifyContent="center">
      <Icon type={EIcon.CART_REPEAT} size={ESize.MD} />
      <Typography variant={ETypographyVariant.H4}>{t('autoSavings.title')}</Typography>
    </Box>
    <Typography textAlign="center">
      <Trans
        i18nKey={`${Translate.common.MODAL}:autoSavings.description`}
        components={{ br: <br /> }}
      />
    </Typography>
  </Modal.Header>
);

export const ModalAutoSavingsHeader = memo(
  withTranslation(ModalHeaderContainer, Translate.common.MODAL),
);
