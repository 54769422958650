import { EFrequency } from '@core/type';

export const daysFrequency: { [key in EFrequency]?: number } = {
  [EFrequency.DAILY]: 1,
  [EFrequency.WEEKLY]: 7,
  [EFrequency.MONTHLY]: 30,
  [EFrequency.QUARTERLY]: 90,
  [EFrequency.SEMIANNUALLY]: 180,
  [EFrequency.ANNUALLY]: 360,
  [EFrequency.NEVER]: 0,
};
