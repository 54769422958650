import { useContextCurrency, useContextUser } from '@core/context';
import { EGTMEventName, GTMDataLayer, GTMProductProps } from '@core/type';
import { CartProductEntity } from '@core/type/api';
import { roundPrice } from '@core/util';

import { useContextGTM } from '../context-gtm';
import { getEcommerceItemProps, getEventOriginUrl, pushGTMEvent } from '../helpers';
import { GTMProductEntity } from '../interface-google-tag-manager';

export const useEventAddToCart = () => {
  const { currency } = useContextCurrency();
  const { currency: userCurrency } = useContextUser();
  const { enable, baseDatalayerProps } = useContextGTM();

  const pushEventAddToCart = (
    products: GTMProductEntity[],
    productsResponse: CartProductEntity[],
  ) => {
    if (enable) {
      let totalValue = 0;

      const items: GTMProductProps[] = products.map((product) => {
        const {
          totalPrice: cartTotalsPrice,
          quantity,
          metalPrice,
        } = productsResponse.find(({ id }) => id === product.id);

        const totalPrice = (cartTotalsPrice / quantity) * product.quantity;

        totalValue += totalPrice;

        return getEcommerceItemProps({ ...product, totalPrice, metalPrice });
      });

      const data: GTMDataLayer = {
        ...baseDatalayerProps,
        eventOrigin: getEventOriginUrl(baseDatalayerProps),
        event: EGTMEventName.ADD_TO_CART,
        ecommerce: {
          value: roundPrice(totalValue),
          currency: userCurrency || currency,
          items,
        },
      };

      pushGTMEvent(data);
    }
  };

  return { pushEventAddToCart };
};
