import { mixed, number, object, SchemaOf, string } from 'yup';

import { AutoSavingsFrequency, Datetime, EFrequency, EPaymentType } from '../interface';

export type ModalAutoSavingsForm = {
  frequency: AutoSavingsFrequency;
  quantity: number;
};

export type AutoSavingsFrequencyForm = {
  frequency: AutoSavingsFrequency;
  nextOrderAt: Datetime;
};

export type AutoSavingsPaymentMethodForm = {
  paymentType: EPaymentType;
};

export const modalAutoSavingsValidator: SchemaOf<ModalAutoSavingsForm> = object().shape({
  frequency: mixed().oneOf(Object.values(EFrequency)).required(),
  quantity: number().required(),
});

export const autoSavingsFrequencyValidator: SchemaOf<AutoSavingsFrequencyForm> = object().shape({
  frequency: mixed().oneOf(Object.values(EFrequency)).required(),
  nextOrderAt: string().required(),
});

export const autoSavingsPaymentTypeValidator: SchemaOf<AutoSavingsPaymentMethodForm> =
  object().shape({
    paymentType: mixed().oneOf(Object.values(EPaymentType)).required(),
  });
