import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import { FieldErrorsImpl, FieldName } from 'react-hook-form';

import {
  FieldErrors,
  InputPhoneControl,
  InputWrapper,
  SelectPhoneCodeControl,
} from '@core/component';
import { useContextUser } from '@core/context';
import { useQueryApi } from '@core/hook';
import { CreateProductAlertForm, StockAlertForm } from '@core/type';
import { CountriesEntity, EQueryKey } from '@core/type/api';
import { formatPhoneNumberInternational, formatPhoneNumberNational } from '@core/util';

import { LinePhoneNumberProps } from '../interface-modal-alert';
import { TransitionWrapper } from './TransitionWrapper';

export const LinePhoneNumber = <T extends CreateProductAlertForm | StockAlertForm>({
  control,
  errors,
  isSendSms,
  phoneCode,
}: LinePhoneNumberProps<T>) => {
  const { phone } = useContextUser();

  const { data: countriesData, isSuccess } = useQueryApi<CountriesEntity>(EQueryKey.COUNTRIES);

  const phoneNumber = phone?.countryIso
    ? formatPhoneNumberInternational(phone.countryIso, phone.number)
    : null;
  const shouldShowPhoneNumberInput = !phoneNumber && isSendSms;

  const format = (phoneNumber: string) => formatPhoneNumberNational(phoneCode, phoneNumber);

  return (
    <TransitionWrapper isOpen={shouldShowPhoneNumberInput}>
      {shouldShowPhoneNumberInput && (
        <>
          <InputWrapper
            display={'flex'}
            height={'48px'}
            position="relative"
            padding={!isSuccess ? '0 16px' : null}
          >
            {isSuccess && (
              <SelectPhoneCodeControl
                control={control}
                name="phoneCode"
                countries={countriesData.countries.map(({ countryIso, name }) => ({
                  iso: countryIso,
                  name,
                }))}
              />
            )}
            <InputPhoneControl control={control} required name="phoneNumber" format={format} />
          </InputWrapper>
          <ErrorMessage
            errors={errors}
            name={
              'phoneNumber' as FieldName<FieldValuesFromFieldErrors<Partial<FieldErrorsImpl<T>>>>
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            render={({ message }) => <FieldErrors message={message} />}
          />
          <ErrorMessage
            errors={errors}
            name={'phone' as FieldName<FieldValuesFromFieldErrors<Partial<FieldErrorsImpl<T>>>>}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            render={({ message }) => <FieldErrors message={message} />}
          />
        </>
      )}
    </TransitionWrapper>
  );
};
