import { MouseEventHandler } from 'react';
import { useTheme } from 'styled-components';

import { LinkTranslated } from '@core/component';
import { useModalStockAlert, useProductLinkProps } from '@core/hook';
import { EActionAttribute, ThemeProps } from '@core/type';

import { ModalAddStockAlert, ModalUpdateStockAlert } from '../modal';
import { CardProduct } from './CardProduct';
import { ProductCardProps } from './interface-product-card';

export const ProductCardLink = ({
  product,
  isAutoSavingsButton,
  onProductTransition,
  onClick,
  ...restCardProductProps
}: Omit<ProductCardProps, 'onAlertClick'> & {
  onProductTransition?: MouseEventHandler;
}) => {
  const {
    structure: {
      productCard: { link: productCardLinkStyles },
    },
  } = useTheme() as ThemeProps;
  const { addingProps, updatingProps, isUpdatingModal, onAlertButton } =
    useModalStockAlert(product);

  const { slug, metalIso } = product;
  const linkProps = useProductLinkProps({ metalIso, slug });

  return (
    <>
      <LinkTranslated
        {...linkProps}
        {...productCardLinkStyles}
        isDraggable={false}
        as={EActionAttribute.SPAN}
        onClick={onClick}
      >
        <CardProduct
          product={product}
          isAutoSavingsButton={isAutoSavingsButton}
          onAlertClick={onAlertButton}
          {...restCardProductProps}
        />
      </LinkTranslated>
      {isUpdatingModal ? (
        <ModalUpdateStockAlert {...updatingProps} />
      ) : (
        <ModalAddStockAlert {...addingProps} />
      )}
    </>
  );
};
