import { Modal, Typography } from '@core/component';
import { EColor, ETypographyVariant } from '@core/type';

export const HeaderModal = ({ title, subTitle }: { title: string; subTitle: string }) => (
  <Modal.Header>
    <Typography variant={ETypographyVariant.H5}>{title}</Typography>
    <Typography
      variant={ETypographyVariant.H5}
      color={{ commonType: EColor.BLACK, intensity: EColor.R900 }}
    >
      {subTitle}
    </Typography>
  </Modal.Header>
);
