import { addDays } from 'date-fns';

import { daysFrequency } from '@core/constant';
import { AutoSavingsFrequency, EDateFormat, ELanguageTags } from '@core/type';

import { formatDate } from '../src/date';

const NEXT_DATES_AMOUNT = 4;

export const getAutoSavingDates = (
  date: string,
  frequency: AutoSavingsFrequency,
  locale: ELanguageTags,
): string[] => {
  const currentDate = new Date(date);
  const freq = daysFrequency[frequency];
  const dates = Array.from({ length: NEXT_DATES_AMOUNT }, (_, i) =>
    addDays(currentDate, freq * (i + 1)),
  );

  return dates.map((date) => formatDate(date.toDateString(), locale, EDateFormat.ddMMyyyy));
};
