import { useState } from 'react';

import { Box, Label } from '@core/component';
import { ELabel } from '@core/type';

import { ModalWhatIsPreorder } from '../modal/modal-what-is-preorder';

export const PreorderBadge = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box hoverStyles={{ cursor: 'pointer' }}>
      <Box
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Label type={ELabel.PREORDER} withInfoIcon />
      </Box>
      <ModalWhatIsPreorder isOpen={isOpen} onCloseModal={() => setIsOpen(false)} />
    </Box>
  );
};
