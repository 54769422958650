import Trans from 'next-translate/Trans';
import withTranslation from 'next-translate/withTranslation';
import { FC, memo, ReactNode, useEffect, useState } from 'react';

import { Paper, Typography } from '@core/component';
import { Translate } from '@core/constant';
import {
  AutoSavingsFrequency,
  Datetime,
  EActionAttribute,
  EColor,
  EDateFormat,
  EDateTimezone,
  EFontWeight,
  EFrequency,
  ELanguageTags,
  WithTranslation,
} from '@core/type';
import { formatDate, getAutoSavingDates } from '@core/util';

const BoldText: FC<{ children?: ReactNode }> = ({ children }) => (
  <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD}>
    {children}
  </Typography>
);

const AutoSavingsNextPurchaseInfoBlockContainer = ({
  i18n: { t },
  frequency,
  language,
  isFirstPurchase = true,
  nextOrderDate,
}: WithTranslation & {
  language: ELanguageTags;
  frequency: AutoSavingsFrequency;
  isFirstPurchase?: boolean;
  nextOrderDate?: Datetime;
}) => {
  const [date, setDate] = useState<string>(null);
  useEffect(() => {
    const currentDate = new Date(nextOrderDate || Date.now());
    currentDate.setMinutes(0);
    currentDate.setHours(currentDate.getHours() + 1);

    setDate(currentDate.toISOString());
  }, []);

  if (!frequency) {
    return null;
  }

  const infoText = isFirstPurchase ? 'firstNextPurchaseTime' : 'nextPurchaseTime';

  return (
    <Paper
      borderColor={{ commonType: EColor.YELLOW, intensity: EColor.R50 }}
      backgroundColor={{ semanticType: EColor.WARNING, variant: EColor.LIGHT }}
      padding={'24px'}
      borderWidth={'1px'}
    >
      {frequency === EFrequency.NEVER ? (
        <Typography>
          <Trans
            i18nKey={`${Translate.common.MODAL}:autoSavings.productRegularPurchase`}
            components={{ boldText: <BoldText /> }}
          />
        </Typography>
      ) : (
        <>
          {date && (
            <Typography>
              <Trans
                i18nKey={`${Translate.common.MODAL}:autoSavings.${infoText}`}
                values={{
                  time: formatDate(date, language, EDateFormat.hhmm, EDateTimezone.EUROPE_PARIS),
                  dates: getAutoSavingDates(date, frequency, language).join(', '),
                }}
                components={{ boldText: <BoldText /> }}
              />
            </Typography>
          )}
          {isFirstPurchase && (
            <Typography marginTop={'8px'}>{t('autoSavings.canAlwaysChange')}</Typography>
          )}
        </>
      )}
    </Paper>
  );
};

export const AutoSavingsNextPurchaseInfoBlock = memo(
  withTranslation(AutoSavingsNextPurchaseInfoBlockContainer, Translate.common.MODAL),
);
