import { ReactNode, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { usePrevious } from '@core/hook';

const expandingAnimation = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 70px;
    opacity: 1;
  }
`;

const collapsingAnimation = keyframes`
  from {
    height: 70px;
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

type TransitionWrapperProps = {
  isOpen: boolean;
  children: ReactNode;
};

export const TransitionWrapper = styled.div<TransitionWrapperProps>`
  ${(props: TransitionWrapperProps) => {
    const { isOpen } = props;

    const [enableAnimation, setEnableAnimation] = useState(false);

    const isPreviouslyOpen = usePrevious(isOpen);

    useEffect(() => {
      if ((isOpen && !isPreviouslyOpen) || (!isOpen && isPreviouslyOpen)) {
        setEnableAnimation(true);
      }
    }, [isOpen, isPreviouslyOpen]);

    return css`
      animation: ${isOpen
          ? enableAnimation && expandingAnimation
          : enableAnimation && collapsingAnimation}
        0.35s ease-out;
      height: ${isOpen ? '70px' : '0px'};
    `;
  }}
`;
