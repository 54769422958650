import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { EActionAttribute, EFontWeight, ETypographyVariant } from '@core/type';
import { formatPhoneNumberInternational } from '@core/util';

const LabelAlertPhoneNumber = ({
  textPhoneDefined,
  textPhoneNotDefined,
}: {
  textPhoneDefined: string;
  textPhoneNotDefined: string;
}) => {
  const { phone } = useContextUser();
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  const phoneNumber = phone?.countryIso
    ? formatPhoneNumberInternational(phone.countryIso, phone.number)
    : null;

  return (
    <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.BODY3}>
      {phoneNumber ? (
        <Trans
          i18nKey={`${Translate.common.PRODUCT}:${textPhoneDefined}`}
          values={{ phoneNumber }}
          components={{
            boldText: (
              <Typography
                as={EActionAttribute.SPAN}
                fontWeight={EFontWeight.BOLD}
                variant={ETypographyVariant.BODY3}
              />
            ),
          }}
        />
      ) : (
        tProduct(textPhoneNotDefined)
      )}
    </Typography>
  );
};

export const LabelProductAlertPhoneNumber = () => (
  <LabelAlertPhoneNumber
    textPhoneDefined={'modals.addPriceAlert.smsDescriptionPhoneDefined'}
    textPhoneNotDefined={'modals.addPriceAlert.smsDescription'}
  />
);

export const LabelStockAlertPhoneNumber = () => (
  <LabelAlertPhoneNumber
    textPhoneDefined={'stockAlerts.newAlertModal.labelSendSmsPhoneDefined'}
    textPhoneNotDefined={'stockAlerts.newAlertModal.labelSendSms'}
  />
);
