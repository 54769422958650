import { boolean, mixed, number, object, SchemaOf, string } from 'yup';

import { ECountry } from '../interface';

export type CreateAlertForm<T extends object> = T & {
  phoneCode: ECountry;
  phoneNumber: string;
};

export type StockAlertForm = CreateAlertForm<{
  quantity: number;
  isSendSms: boolean;
}>;

export const stockAlertSchema: SchemaOf<StockAlertForm> = object().shape({
  quantity: number().positive().required(),
  isSendSms: boolean(),
  phoneCode: mixed<ECountry>().when('isSendSms', {
    is: true,
    then: mixed<ECountry>().oneOf(Object.values(ECountry)),
  }),
  phoneNumber: string().when('isSendSms', {
    is: true,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    then: string().min(6).required(),
  }),
});
