import useTranslation from 'next-translate/useTranslation';
import withTranslation from 'next-translate/withTranslation';
import { memo } from 'react';

import {
  Box,
  Button,
  IconSuccess,
  Line,
  LinkTranslatedStyledAsButton,
  Modal,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useMediaQuery } from '@core/provider';
import { EButtonVariant, EColor, ERouting, ETypographyVariant, WithTranslation } from '@core/type';

import { ModalSuccessAlertUpdateProps } from '../interface-modal-alert';

const ModalSuccessAlertContainer = ({
  name,
  title,
  linkProps,
  linkTitle,
  onCloseModal,
  isOpen,
  i18n: { t },
}: ModalSuccessAlertUpdateProps & WithTranslation) => {
  const { isPhone } = useMediaQuery();

  return (
    <Modal onCloseModal={onCloseModal} isOpen={isOpen}>
      <Modal.Header>
        <IconSuccess />
        <Typography variant={ETypographyVariant.H5}>{title}</Typography>
        <Typography
          variant={ETypographyVariant.H5}
          color={{ commonType: EColor.BLACK, intensity: EColor.R900 }}
        >
          {name}
        </Typography>
      </Modal.Header>

      <Modal.Body>
        <Box display="flex" justifyContent="center" marginBottom="32px">
          <Typography variant={ETypographyVariant.BODY3}>
            {t('stockAlerts.createdAlertModal.emailOrMessage')}
          </Typography>
        </Box>

        <Line marginBottom="32px" />

        <Box
          display="flex"
          justifyContent={isPhone ? undefined : 'space-between'}
          flexDirection={isPhone ? 'column' : 'row'}
        >
          {isPhone ? (
            <>
              <LinkTranslatedStyledAsButton {...linkProps} isFullWidth={isPhone}>
                {linkTitle}
              </LinkTranslatedStyledAsButton>
              <Button
                variant={EButtonVariant.OUTLINED}
                onClick={onCloseModal}
                isFullWidth={isPhone}
                marginTop="15px"
              >
                {t('stockAlerts.newAlertModal.btnCancel')}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant={EButtonVariant.OUTLINED}
                onClick={onCloseModal}
                isFullWidth={isPhone}
              >
                {t('stockAlerts.newAlertModal.btnCancel')}
              </Button>
              <LinkTranslatedStyledAsButton {...linkProps} isFullWidth={isPhone}>
                {linkTitle}
              </LinkTranslatedStyledAsButton>
            </>
          )}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

const ModalSuccessAlertUpdate = memo(
  withTranslation(ModalSuccessAlertContainer, Translate.common.PRODUCT),
);

export const ModalSuccessProductAlertUpdate = (
  props: Pick<ModalSuccessAlertUpdateProps, 'name' | 'onCloseModal' | 'isOpen'>,
) => {
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  return (
    <ModalSuccessAlertUpdate
      {...props}
      title={tProduct('modals.addPriceAlert.successfullySet')}
      linkTitle={tProduct('modals.addPriceAlert.viewProductAlerts')}
      // TODO[RCT-???]: add scrolling to the section after redirect
      linkProps={{ keyRoute: ERouting.ALERT_SETTINGS }}
    />
  );
};

export const ModalSuccessStockAlertUpdate = (
  props: Pick<ModalSuccessAlertUpdateProps, 'name' | 'onCloseModal' | 'isOpen'>,
) => {
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  return (
    <ModalSuccessAlertUpdate
      {...props}
      title={tProduct('stockAlerts.createdAlertModal.successfullySet')}
      linkTitle={tProduct('stockAlerts.createdAlertModal.btnViewItems')}
      // TODO[RCT-???]: add scrolling to the section after redirect
      linkProps={{ keyRoute: ERouting.ALERT_SETTINGS }}
    />
  );
};
