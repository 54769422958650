import { useEventAddToCart } from '@google-tag-manager';
import { useEventMixpanel } from '@mixpanel';
import useTranslation from 'next-translate/useTranslation';
import { MouseEventHandler } from 'react';

import { Box, Link } from '@core/component';
import { Translate } from '@core/constant';
import { useContextCart, useContextMediaQuery } from '@core/context';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ESize,
  ETypographyVariant,
} from '@core/type';
import { CartProductEntity } from '@core/type/api';

import { LinkAddToCartProps } from './interface-product-card';

export const LinkAddToCart = ({
  product,
  productCount,
  title,
  preorderTitle,
  shippingMethod,
  ...restProps
}: LinkAddToCartProps) => {
  const { addToCart, isReady } = useContextCart();
  const { pushEventAddToCart } = useEventAddToCart();
  const { trackProductAddedToCartEvent } = useEventMixpanel();
  const { t: tButton } = useTranslation(Translate.common.BUTTON);
  const { isMobile } = useContextMediaQuery();

  const handleClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {
    e.preventDefault();

    const productData: CartProductEntity = {
      quantity: productCount ?? 1,
      repeatBuyFrequency: null,
      ...product,
    };

    return void addToCart(productData, false, shippingMethod)?.then(({ products }) => {
      pushEventAddToCart([productData], products);
      trackProductAddedToCartEvent({ ...productData, price: productData.totalPrice });
    });
  };

  const btnTitle = title || tButton('addToCart.title');
  const btnPreorderTitle = preorderTitle || tButton('preorder.title');

  return (
    <Box textAlign="right" onClick={isMobile ? handleClick : null} lineHeight={'48px'}>
      <Link
        as={EActionAttribute.SPAN}
        disabled={!isReady}
        color={EColor.ACCENT}
        startIcon={EIcon.PLUS_ROUNDED}
        iconSize={ESize.MD}
        iconColor={EColor.ACCENT}
        variant={ETypographyVariant.H6}
        fontWeight={EFontWeight.BOLD}
        isChangingColorHover
        isIconFill
        hasEllipsisText
        {...restProps}
        iconTop="7px"
        iconMargin={8}
        onClick={isMobile ? null : handleClick}
      >
        {product?.preorder?.isPreorder ? btnPreorderTitle : btnTitle}
      </Link>
    </Box>
  );
};
