import styled from 'styled-components';

import { Box, ImageApi, Label, LinkTranslatedStyledAsLink } from '@core/component';
import { LinkProps, LinkTranslatedLegacyProps } from '@core/component/interface';
import { useProductLinkProps } from '@core/hook';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import { ELabel, WithThemeProps } from '@core/type';
import { getLinkColorProps } from '@core/util';

import { PreorderBadge } from '../preorder-badge';
import { LinkProductLineProps } from './interface-product-card';

export const LinkProductLine = ({
  name,
  slug,
  metalIso,
  image,
  preorder,
  hideImage,
  withInfoIcon = true,
  width,
}: LinkProductLineProps) => {
  const linkProps: Omit<LinkTranslatedLegacyProps, 'children'> = useProductLinkProps({
    slug,
    metalIso,
  });

  return (
    <Box display="flex" alignItems={'center'} width={width}>
      {!hideImage && (
        <ImageLinkContainer>
          <LinkTranslatedStyledAsLink {...linkProps}>
            <Box display="flex" alignItems="center" height="48px" width="48px">
              <ImageApi
                {...image}
                height={48}
                width={48}
                defaultImage={getStaticPathImage('card/product-default.png')}
                defaultImageBlurData={getStaticPathImageBlurData(
                  EFilenameBlur['card/product-default.png'],
                )}
              />
            </Box>
          </LinkTranslatedStyledAsLink>
        </ImageLinkContainer>
      )}

      <Box marginBottom="auto" marginTop="auto" width="calc(100% - 64px)">
        {preorder?.isPreorder && (
          <Box marginBottom={'8px'}>
            {withInfoIcon ? <PreorderBadge /> : <Label type={ELabel.PREORDER} />}
          </Box>
        )}
        <LinkTranslatedStyledAsLink {...linkProps}>{name}</LinkTranslatedStyledAsLink>
      </Box>
    </Box>
  );
};

export const ImageLinkContainer = styled(Box)<LinkProps>(({
  theme: {
    palette,
    structure: {
      link: { underlineSecondPath },
    },
  },
  color,
}: WithThemeProps & LinkProps) => {
  const {
    hover: { color: hoverColor },
  } = getLinkColorProps({ color, palette });

  return {
    marginRight: '16px',
    '&:hover + div a': {
      ...underlineSecondPath,
      color: hoverColor,
    },
  };
});
