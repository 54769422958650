import useTranslation from 'next-translate/useTranslation';
import withTranslation from 'next-translate/withTranslation';
import { memo } from 'react';

import { Box, Button } from '@core/component';
import { Translate } from '@core/constant';
import { EButtonVariant, WithTranslation } from '@core/type';

const ButtonsModalUpdateAlertContainer = ({
  onDelete,
  onCancel,
  i18n: { t },
}: WithTranslation & {
  onDelete: () => void;
  onCancel: () => void;
}) => {
  const { t: tButton } = useTranslation(Translate.common.BUTTON);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Button type="submit">{tButton('update.title')}</Button>
        <Button
          marginLeft="16px"
          variant={EButtonVariant.OUTLINED}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          {tButton('remove.title')}
        </Button>
      </Box>

      <Button type={'button'} variant={EButtonVariant.OUTLINED} onClick={onCancel}>
        {t('stockAlerts.newAlertModal.btnCancel')}
      </Button>
    </Box>
  );
};

export const ButtonsModalUpdateAlert = memo(
  withTranslation(ButtonsModalUpdateAlertContainer, Translate.common.PRODUCT),
);
