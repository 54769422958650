import { useState } from 'react';

import { useContextAuth } from '@core/context';
import { ProductEntity } from '@core/type/api';

import { useStockAlert } from './use-stock-alert';

export const useModalStockAlert = (product: ProductEntity) => {
  const { stockAlerts } = useStockAlert();
  const { checkSession } = useContextAuth();
  const [isStockAlertModalOpen, setIsStockAlertModalOpen] = useState(false);
  const [isAddStockAlertInProgress, setIsAddStockAlertInProgress] = useState(false);

  const stockAlert =
    stockAlerts?.find(({ product: alertStock }) => alertStock.slug === product.slug) || undefined;

  const isUpdatingModal = stockAlert && !isAddStockAlertInProgress;

  const handleAlertButton = () => {
    setIsStockAlertModalOpen(true);

    if (!stockAlert) {
      setIsAddStockAlertInProgress(true);
    }
  };

  const handleModalClosing = () => {
    if (isUpdatingModal) {
      setIsStockAlertModalOpen(false);
    } else {
      setIsStockAlertModalOpen(false);
      setIsAddStockAlertInProgress(false);
    }
  };

  const defaultProps = {
    isOpen: isStockAlertModalOpen,
    onCloseModal: handleModalClosing,
  };

  return {
    addingProps: { ...defaultProps, product },
    updatingProps: { ...defaultProps, stockAlert },
    isUpdatingModal,
    onAlertButton: () => checkSession(handleAlertButton),
  };
};
