export enum EPaymentType {
  GA_PAY = 'ga_pay',
  CREDIT_CARD = 'credit_card',
  WIRE_TRANSFER = 'wire_transfer',
}

export enum EFullPaymentType {
  GA_PAY = 'GA Pay',
  CREDIT_CARD = 'Credit Card',
  WIRE_TRANSFER = 'Wire Transfer',
}
