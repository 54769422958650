import useTranslation from 'next-translate/useTranslation';
import withTranslation from 'next-translate/withTranslation';
import { memo } from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  Button,
  FormControl,
  InputCheckboxControl,
  Line,
  Modal,
  ModalProps,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { useStockAlert, useValidator } from '@core/hook';
import {
  EButtonVariant,
  EContactMethod,
  ECountry,
  ETypographyVariant,
  StockAlertForm,
  WithTranslation,
  stockAlertSchema,
} from '@core/type';
import { PostStockAlertEntity, ProductEntity } from '@core/type/api';
import { getInternationalPrefix } from '@core/util';

import {
  HeaderModal,
  LabelStockAlertPhoneNumber,
  LinePhoneNumber,
  LineStockAlertField,
  ModalSuccessStockAlertUpdate,
} from './components';

const ModalAddStockAlertContainer = ({
  i18n: { t },
  product,
  isOpen,
  onCloseModal,
}: Omit<ModalProps, 'children'> & WithTranslation & { product: ProductEntity }) => {
  const { phone } = useContextUser();
  const { addToStockAlerts } = useStockAlert();
  const { t: tForm } = useTranslation(Translate.common.FORM);

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setError,
    watch,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm<StockAlertForm>({
    defaultValues: {
      phoneCode: phone?.countryIso ?? ECountry.SWITZERLAND,
      phoneNumber: phone?.number ?? '',
      isSendSms: false,
    },
    resolver: useValidator(stockAlertSchema, tForm),
    mode: 'onChange',
  });

  const isSendSms = watch('isSendSms', false);

  const handleFormCb = (data: StockAlertForm) => {
    const submitData: PostStockAlertEntity = {
      quantity: data.quantity,
      productId: product?.id,
      isSendSms: data.isSendSms,
      phone: isSendSms
        ? {
            code: phone?.code ?? getInternationalPrefix(data.phoneCode),
            countryIso: phone?.countryIso ?? data.phoneCode,
            number: phone?.number ?? data.phoneNumber,
          }
        : undefined,
    };

    return addToStockAlerts({
      submitData,
      product,
      quantity: submitData.quantity,
      notification: data.isSendSms ? EContactMethod.SMS : EContactMethod.EMAIL,
    });
  };

  const handleModalClose = () => {
    onCloseModal();
    reset();
  };

  return (
    <>
      <Modal isOpen={isOpen && !isSubmitSuccessful} onCloseModal={handleModalClose}>
        <HeaderModal title={t('stockAlerts.newAlertModal.stockAlert')} subTitle={product?.name} />
        <Modal.Body>
          <Box display="flex" justifyContent="center">
            <Typography variant={ETypographyVariant.BODY3}>
              {t('stockAlerts.newAlertModal.letUsKnow')}
            </Typography>
          </Box>
          <Line margin={'22px 0'} />

          <Typography marginBottom={'16px'}>
            {t('stockAlerts.newAlertModal.yourQuantity')}
          </Typography>

          <FormControl
            t={tForm}
            trigger={trigger}
            reset={reset}
            handleSubmit={handleSubmit}
            setError={setError}
            fn={handleFormCb}
            onSuccess={reset}
          >
            <LineStockAlertField control={control} product={product} errors={errors} />

            <Box margin={'24px 0 16px'}>
              <InputCheckboxControl
                control={control}
                name="isSendSms"
                label={<LabelStockAlertPhoneNumber />}
              />
            </Box>

            <LinePhoneNumber
              control={control}
              errors={errors}
              phoneCode={getValues('phoneCode')}
              isSendSms={isSendSms}
            />

            <Line marginTop="16px" marginBottom="24px" />

            <Box display="flex" justifyContent="space-between">
              <Button type="submit">{t('stockAlerts.newAlertModal.btnAddAlert')}</Button>

              <Button
                variant={EButtonVariant.OUTLINED}
                onClick={(e) => {
                  e.preventDefault();
                  handleModalClose();
                }}
              >
                {t('stockAlerts.newAlertModal.btnCancel')}
              </Button>
            </Box>
          </FormControl>
          <Typography marginTop={'32px'} variant={ETypographyVariant.CAPTION3}>
            {t('stockAlerts.newAlertModal.confirmYourConsent')}
          </Typography>
        </Modal.Body>
      </Modal>
      <ModalSuccessStockAlertUpdate
        name={product?.name}
        isOpen={isOpen && isSubmitSuccessful}
        onCloseModal={handleModalClose}
      />
    </>
  );
};

export const ModalAddStockAlert = memo(
  withTranslation(ModalAddStockAlertContainer, Translate.common.PRODUCT),
);
