import Trans from 'next-translate/Trans';
import withTranslation from 'next-translate/withTranslation';
import { MouseEvent, MouseEventHandler, memo } from 'react';
import styled, { useTheme } from 'styled-components';

import {
  Box,
  Image,
  ImageApi,
  Link,
  LinkAddToWishlist,
  ProductLabel,
  TextWithLineLimit,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextCurrency, useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ELabel,
  ESize,
  ETypographyVariant,
  EZIndexName,
  ThemeProps,
  WithThemeProps,
  WithTranslation,
} from '@core/type';
import { EDeliveryType } from '@core/type/api';
import { formatCurrency, getPaletteHandlers } from '@core/util';

import { LinkAddToCart } from './LinkAddToCart';
import { LinkAutoSavingsBadge } from './LinkAutoSavingsBadge';
import { ProductCardProps } from './interface-product-card';

export const CardProductContainer = ({
  i18n: { t },
  product,
  removeFromWishList,
  onAlertClick,
  titleTag,
  isAutoSavingsButton,
}: ProductCardProps & WithTranslation) => {
  const {
    structure: {
      productCard: {
        base: productCardBaseStyles,
        imageContainer: { width: imageContainerWidth, height: imageContainerHeight },
        statusLabel: { container: statusLabelContainerStyles },
      },
    },
  } = useTheme() as ThemeProps;
  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  const handleAlertButton: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {
    e.preventDefault();
    onAlertClick();
  };

  const { isMobile } = useContextMediaQuery();

  return (
    <Box
      {...productCardBaseStyles}
      zIndex={EZIndexName.DEFAULT}
      hoverStyles={{ zIndex: EZIndexName.HOVER_EFFECT }}
    >
      <CardContent>
        <Box display="flex" flexDirection="column" height={'100%'} position="relative" gap="8px">
          <Box marginBottom={'8px'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Box position={'relative'} height={imageContainerHeight} width={imageContainerWidth}>
                <ImageApi
                  {...product?.image}
                  defaultImage={getStaticPathImage('card/product-default.png')}
                  defaultImageBlurData={getStaticPathImageBlurData(
                    EFilenameBlur['card/product-default.png'],
                  )}
                  loading={'lazy'}
                  alt={product?.name}
                  draggable={false}
                  sizes="200px"
                />
              </Box>

              <Box display={'flex'} {...statusLabelContainerStyles}>
                {product?.deal ? (
                  <ProductLabel
                    display="flex"
                    gap="3px"
                    alignItems="center"
                    labelColor={EColor.DANGER}
                  >
                    <Trans
                      i18nKey={`${Translate.common.PRODUCT}:labels.percentage`}
                      values={{ percentage: product?.deal.percentage }}
                    />
                  </ProductLabel>
                ) : (
                  <>
                    {product?.isNew && !product?.isTopSales && (
                      <ProductLabel
                        type={ELabel.NEW}
                        display="flex"
                        gap="3px"
                        alignItems="center"
                        labelColor={EColor.SECONDARY}
                      >
                        <Image
                          alt={`Rocket icon`}
                          width={14}
                          height={14}
                          style={{ objectFit: 'cover' }}
                          src={getStaticPathImage('product/rocket.png')}
                          blurDataURL={getStaticPathImageBlurData(
                            EFilenameBlur['product/rocket.png'],
                          )}
                        />{' '}
                        {t('labels.new').toUpperCase()}
                      </ProductLabel>
                    )}
                    {product?.isTopSales && (
                      <ProductLabel
                        display="flex"
                        gap="3px"
                        alignItems="center"
                        labelColor={EColor.SECONDARY}
                      >
                        <Image
                          alt={`Fire icon`}
                          width={14}
                          height={14}
                          style={{ objectFit: 'cover' }}
                          src={getStaticPathImage('product/fire.png')}
                          blurDataURL={getStaticPathImageBlurData(
                            EFilenameBlur['product/fire.png'],
                          )}
                        />{' '}
                        {t('labels.top').toUpperCase()}
                      </ProductLabel>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box minHeight={isMobile ? '44px' : '46px'}>
            <TextWithLineLimit
              as={titleTag}
              variant={ETypographyVariant.BODY2}
              color={EColor.PRIMARY}
              marginBottom={isMobile ? '8px' : '0'}
            >
              {product?.name}
            </TextWithLineLimit>
          </Box>
          <Box minHeight={'48px'} marginBottom={'8px'}>
            {product?.isOutOfStock ? (
              <Typography variant={ETypographyVariant.H6} color={EColor.SECONDARY}>
                {t('statuses.outOfStock')}
              </Typography>
            ) : (
              <>
                {product.deal ? (
                  <>
                    <Typography
                      variant={ETypographyVariant.H5}
                      color={EColor.DANGER}
                      textDecoration="underline"
                      marginBottom={'4px'}
                    >
                      {formatCurrency(
                        language,
                        product?.currencyIso || currency,
                        product?.totalPriceWithDeal,
                      )}
                    </Typography>
                    <Typography
                      variant={ETypographyVariant.H6}
                      color={EColor.SECONDARY}
                      textDecoration="line-through"
                    >
                      {formatCurrency(
                        language,
                        product?.currencyIso || currency,
                        product?.totalPrice,
                      )}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant={ETypographyVariant.H5}
                    color={EColor.PRIMARY}
                    marginBottom={'8px'}
                  >
                    {formatCurrency(
                      language,
                      product?.currencyIso || currency,
                      product?.totalPrice,
                    )}
                  </Typography>
                )}
              </>
            )}
          </Box>

          {product?.isOutOfStock ? (
            <Box
              onClick={isMobile ? handleAlertButton : null}
              textAlign="right"
              lineHeight={'48px'}
            >
              <Link
                startIcon={EIcon.ALERT_ROUNDED}
                as={EActionAttribute.SPAN}
                iconSize={ESize.MD}
                iconColor={EColor.ACCENT}
                variant={ETypographyVariant.H6}
                fontWeight={EFontWeight.BOLD}
                isChangingColorHover={true}
                isIconFill={true}
                iconTop="7px"
                iconMargin={8}
                color={EColor.ACCENT}
                onClick={isMobile ? null : handleAlertButton}
              >
                {t('buttons.alertMe')}
              </Link>
            </Box>
          ) : (
            <Box onClick={(e) => e.stopPropagation()}>
              {isAutoSavingsButton ? (
                <LinkAutoSavingsBadge product={product} />
              ) : (
                <LinkAddToCart
                  product={product}
                  shippingMethod={{
                    shippingType: EDeliveryType.STORAGE,
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </CardContent>
      {removeFromWishList && (
        <Box marginTop={'16px'}>
          <LinkAddToWishlist
            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              removeFromWishList();
            }}
            isActive
            variant={ETypographyVariant.BODY3}
            isMobile={isMobile}
          />
        </Box>
      )}
    </Box>
  );
};

export const CardProduct = memo(withTranslation(CardProductContainer, Translate.common.PRODUCT));

export const CardContent = styled(Box)(({
  theme: {
    palette,
    structure: {
      productCard: {
        content: { base: baseStyles },
      },
    },
  },
}: WithThemeProps) => {
  const { getBackgroundColor, getColor, getBoxShadow } = getPaletteHandlers(palette);
  return {
    ...baseStyles,
    background: getBackgroundColor({ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }),
    border: `1px solid ${getColor({ commonType: EColor.GRAY, intensity: EColor.A200 })}`,
    '@media (hover: hover)': {
      '&:hover': {
        background: getBackgroundColor({ commonType: EColor.WHITE, intensity: EColor.R900 }),
        boxShadow: getBoxShadow(EColor.ELEVATION_4),
      },
    },
  };
});
