import { metalToSlug } from '@core/constant';
import { ERouting } from '@core/type';
import { ProductEntity } from '@core/type/api';

import { useLanguage } from './use-language';

export const useProductLinkProps = ({
  metalIso,
  slug,
}: Pick<ProductEntity, 'metalIso' | 'slug'>) => {
  const { language } = useLanguage();

  return {
    keyRoute: ERouting.PRODUCT_SHOW,
    query: {
      metal: metalToSlug[language][metalIso],
      slug,
    },
  };
};
