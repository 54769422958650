import Trans from 'next-translate/Trans';
import withTranslation from 'next-translate/withTranslation';
import styled from 'styled-components';

import { ButtonClose, Modal, ModalBackgroundProps, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EActionAttribute, ESize, ETypographyVariant, WithTranslation } from '@core/type';

const List = styled.ol`
  list-style-position: inside;
`;

const ModalWhatIsPreorderBase = ({
  isOpen,
  onCloseModal,
  i18n: { t },
}: ModalBackgroundProps & WithTranslation) => {
  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} size={ESize.LG}>
      <Modal.Header>
        <Typography variant={ETypographyVariant.H2} textAlign="center">
          {t('modals.preorderInfo.whatIsPresale')}
        </Typography>
      </Modal.Header>

      <Typography marginBottom="24px">
        <Trans
          i18nKey={`${Translate.common.PRODUCT}:modals.preorderInfo.ourPresaleOption`}
          components={{
            boldText: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6} />,
          }}
        ></Trans>
      </Typography>

      <Typography marginBottom="24px">{t('modals.preorderInfo.productsWithDelay')}</Typography>

      <Typography marginBottom="8px" variant={ETypographyVariant.H6}>
        {t('modals.preorderInfo.howPresaleWork')}
      </Typography>

      <List>
        <Typography as={EActionAttribute.LI} marginBottom="8px">
          {t('modals.preorderInfo.1OrderProduct')}
        </Typography>
        <Typography as={EActionAttribute.LI} marginBottom="8px">
          {t('modals.preorderInfo.2WeGiveEstimatedDate')}
        </Typography>
        <Typography as={EActionAttribute.LI} marginBottom="8px">
          {t('modals.preorderInfo.3ProductReady')}
        </Typography>
      </List>

      <Modal.Footer display="flex" justifyContent="center">
        <ButtonClose onClick={onCloseModal} />
      </Modal.Footer>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ModalWhatIsPreorder = withTranslation(
  ModalWhatIsPreorderBase,
  Translate.common.PRODUCT,
);
